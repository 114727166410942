'use client'; // Error components must be Client Components

import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
	return (
		<div className="flex h-[400px] w-full flex-col items-center justify-center">
			<div className="mb-6 max-w-prose">
				<h2 className="mb-6 text-2xl font-semibold">{error.message}</h2>
				<div className="hover:text-UC-purple-700 flex items-center space-x-2 font-light">
					<a href="https://utahcounty.jotform.com/241126558789873" rel="noopener" target="_blank">
						File a bug report and the site maintainers will be notified.
					</a>
					<QuestionMarkCircleIcon className="h-[20px]" />
				</div>
			</div>

			<button
				className="border-UC-purple-700/0 hover:border-UC-purple-700 border-b-2 font-semibold"
				onClick={
					// Attempt to recover by trying to re-render the segment
					() => reset()
				}
				type="button"
			>
				Try again
			</button>
		</div>
	);
}
